<template>
    <v-card rounded="lg">
        <v-card-title style="border-bottom:1px solid #ddd">
            <h5 class="pb-0"><b>{{ $t('missionAgenda') }}</b></h5>
            <v-spacer></v-spacer>
           
        </v-card-title>
        <v-card-text>
            <v-sheet height="64" id="calendarSheet">
                <v-toolbar
                flat
                style="width:100%;"
                class="px-0"
                >
                    <div style="width:100%;" class=" d-flex justify-space-between align-center">
                    <span>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                        >
                            <v-icon small>
                        $prev
                            </v-icon>
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                        >
                            <v-icon small>
                            $next
                            </v-icon>
                        </v-btn>
                        
                    </span>
                    <p class="mb-0"><b>{{currentMonth}}</b></p>
                    <v-btn
                            outlined
                            small
                            class="ml-4"
                            color="grey darken-2"
                            @click="setToday"
                        >
                            <span style="font-size:12px;">{{$t('today')}}</span>
                        </v-btn>
                    </div>
                </v-toolbar>
                
            </v-sheet>
            <v-toolbar flat height="500">
                <v-calendar
                ref="calendarMission"
                class="marketingCalendar"
                v-model="focus"
                color="primary"
                locale="fr"
                style="width:100%;"
                :events="events"
                :type="type"
                :event-ripple="false"
                event-more-text="+ {0}"
                :weekdays="[1,2,3,4,5,6,0]"
                @click:event="eventHover"
                @click:more="eventMore"
                ></v-calendar>

                <v-dialog hide-overlay scrollable :attach="'.marketingCalendar'" v-model="moreDialog">
                    <v-card>
                        <v-toolbar tile flat dark  class="bg-gradient" height="45" >
                            <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                                <div class="d-flex align-center">
                                    <v-toolbar-title class="d-flex align-center">{{$t('operationList')}}</v-toolbar-title>
                                </div>
                                <div>
                                    <v-btn text dark depressed @click.native="moreDialog = false">
                                        <v-icon small>$close</v-icon>
                                    </v-btn>
                                </div>
                            </div> 
                        </v-toolbar>
                    
                        <v-card-text>
                            <v-list dense>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in eventDetails"
                                        :key="i"
                                        @click="clickEventMore(item)"
                                        >
                                        <div class="d-flex justify-space-between" style="width:100%;">
                                            <span>
                                                {{item.name}}
                                            </span>
                                            <span v-if="item.social_raison">
                                                {{ $t('entity') }} : {{ item.social_raison }}
                                            </span>
                                        </div>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-toolbar>
            
        </v-card-text>
        <v-card-actions v-if="$func.hasRight('profile/is_coach')" class="pb-5 pt-0 justify-end">
            <div class="preresaBtn mr-3" style="font-weight:normal;">
                <v-btn @click="createResa" small depressed color="primary">{{ $t('addResa') }}</v-btn>
            </div>
            <div class="indispoBtn mr-6" style="font-weight:normal;">
                <v-btn @click="setIndispo" small depressed color="primary">{{ $t('set-indisponibility') }}</v-btn>    
            </div>
            <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
        </v-card-actions>
    </v-card>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from "@/mixins/mixins";

export default {
    name:"missionCalendar",
    props:['missions', 'indisponibilities'],
    mixins: [dateManipulation, getForm],
    components: {
        FormDialogComponent
    },
    data() {
        return {
            type: 'month',
            focus: new Date(),
            events: [],
            moreDialog: false,
            eventDetails: [],
            form:null,
            formUrl:null
        }
    },
    watch: {
        missions(){
            this.initEvents();
        },
        dialogPlanningUserIndispo() {
            this.$emit('initData');
        }
    },
    created() {
        if(this.missions && this.missions.length > 0)
            this.initEvents();
    },
    computed: {
        currentMonth(){
            if(this.type == 'month')
                return this.capitalizeFirstLetter(this.timestampToDate((Date.parse(this.focus)/1000), 'monthYear'));
            else 
                return ''
        },
        dialogPlanningUserIndispo() {
            return this.$store.state.agenda.openIndispoDialog
        }
    },
    methods: {
        createResa(){
            this.formUrl = '/user/getFormReservationMobile?id='+this.$store.state.auth.user.id
            this.getForm('/user/getFormReservationMobile?id='+this.$store.state.auth.user.id)
        },
        setIndispo(start_date){
            this.$store.dispatch("agenda/SET_STARTDATE_AGENDA", start_date);
            this.$store.dispatch("agenda/SET_OPEN_INDISPO_USER", !this.$store.state.agenda.openIndispoDialog);
        },
        next(){
            this.$refs.calendarMission.next()
            this.initEvents();
        },
        prev(){
            this.$refs.calendarMission.prev()
            this.initEvents();
        },
        setToday () {
            this.focus = new Date();
            this.initEvents();
        },
        getRecurenceDates(currentMonth, baseDate) {
            let dates = [];
            let date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
            let month = date.getMonth();
            let dayOfWeek = new Date(baseDate).getDay();

            // Trouver le premier jour spécifique du mois
            let decalage = (7 + dayOfWeek - date.getDay()) % 7;
            date.setDate(date.getDate() + decalage);

            // Ajouter tous les jours spécifiques du mois
            while (date.getMonth() === month) {
                dates.push(date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).substr(-2) + '-' + ("0" + date.getDate()).substr(-2));
                date.setDate(date.getDate() + 7);
            }

            return dates;
        },
        initEvents(){
            this.events = [];
            this.indisponibilities.forEach(element => {
                let dates = [element['date']];
                if(element.type === "hebdomadaire")
                    dates = this.getRecurenceDates(this.focus, new Date(element['date']));

                dates.forEach(date => {
                    this.events.push({
                        name: element.label ? element.label : this.$t("indispoUser"),
                        id: element.id,
                        start: date,
                        // start: date + " " + element['start'],
                        end: (element.date_end ? element.date_end : date) + " " + (element['end'] === "24:00:00" ? "23:59:59" : element['end']),
                        color: element.label ? "#ff9501" : "grey",
                        is_indispo: true,
                        source: element.source,
                        attr_date_start: [date]
                    })
                });
            });
            this.missions.forEach(element => {
                if(element['config.date_start'] && element['config.date_end'])
                    if(this.$store.state.auth.user.is_presta && !this.$func.hasRight('profile/is_manager') && !element.provider_users_confirmed.includes(this.$store.state.auth.user.id))
                        element.status.color += '-opacity-50';

                    this.events.push({
                        name: element.name,
                        id: element.id,
                        start:new Date(element['config.date_start'].split(" ").join("T")),
                        end: new Date(element['config.date_end'].split(" ").join("T")),
                        color: element.status.color,
                        provider_users_confirmed: element.provider_users_confirmed,
                        attr_date_start: [element['config.date_start'].split(" ")[0]]
                    })
            });
        },
        eventMore($evt){
            let date = $evt.date
            this.eventDetails = this.events.filter((e) => e.attr_date_start.includes(date))
            this.moreDialog = true
        },
        clickEventMore(event){
            console.log("toto", event)
            if(!event.is_indispo) {
                // Si c'est un coach alors on vérifie qu'il est confirmé sur la mission pour y accéder
                if(!this.$store.state.auth.user.is_presta || this.$func.hasRight('profile/is_manager') || event.provider_users_confirmed.includes(this.$store.state.auth.user.id))
                    this.$router.push({name: 'MissionDashboard', params:{mission_id: event.id}});
            } else if(event.source === 'user') {
                this.setIndispo(event.event.start);
            }
        },
        eventHover($evt){
            console.log("toto", $evt)
            if(!$evt.event.is_indispo) {
                // Si c'est un coach alors on vérifie qu'il est confirmé sur la mission pour y accéder
                if(!this.$store.state.auth.user.is_presta || this.$func.hasRight('profile/is_manager') || $evt.event.provider_users_confirmed.includes(this.$store.state.auth.user.id))
                    this.$router.push({name: 'MissionDashboard', params:{mission_id: $evt.event.id}});
            } else if($evt.event.source === "user") {
                this.setIndispo($evt.event.start);
            }
        },
        capitalizeFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1);
        },
        customRefresh() {
            this.$emit('initData');
        }
    },
}
</script>
<style lang="scss">
    .theme--light.v-calendar-events .v-calendar-weekly__day .v-event-more {
        margin-left:1px;
        background-color: rgba(255,255,255,0) !important;
    }
    .marketingCalendar {
        
        .v-calendar-weekly__day-label {
            .v-btn--fab.v-size--small {
                width:25px;
                height:25px;
                .v-btn__content {
                    font-size:12px;
                }
            }
        }

        .success-opacity-50 { background-color: #5bb85d7F !important; border-color: #5bb85d7F !important; }
        .warning-opacity-50 { background-color: #efad4d7F !important; border-color: #efad4d7F !important; }
        .purple-opacity-50 { background-color: #6208ff7F !important; border-color: #6208ff7F !important; }
        .primary-opacity-50 { background-color: #1614597F !important; border-color: #1614597F !important; }
        .error-opacity-50 { background-color: #d9544f7F !important; border-color: #d9544f7F !important; }
        .grey-opacity-50 { background-color: #9e9e9e7F !important; border-color: #9e9e9e7F !important; }
        .info-opacity-50 { background-color: #347ab67F !important; border-color: #347ab67F !important; }
    }
</style>