<template>
    <v-card rounded="lg">
        <v-card-title style="border-bottom:1px solid #ddd">
            <h5 class="pb-0"><b>{{ $t('operationAgenda') }}</b></h5>
            <v-spacer></v-spacer>
            <p class="mb-0"><b>{{currentMonth}}</b></p>
        </v-card-title>
        <v-card-text>
            <v-sheet height="64" id="calendarSheet">
                <v-toolbar
                flat
                style="width:100%;"
                class="px-0"
                >
                    <div style="width:100%;" class=" d-flex justify-space-between align-center">
                    <span>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                        >
                            <v-icon small>
                        $prev
                            </v-icon>
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                        >
                            <v-icon small>
                            $next
                            </v-icon>
                        </v-btn>
                        
                    </span>
                    <p class="mb-0"><b>{{currentMonth}}</b></p>
                    <v-btn
                            outlined
                            small
                            class="ml-4"
                            color="grey darken-2"
                            @click="setToday"
                        >
                            <span style="font-size:12px;">{{$t('today')}}</span>
                        </v-btn>
                    </div>
                </v-toolbar>
                
            </v-sheet>
            <v-toolbar flat height="500">
                <v-calendar
                ref="calendar"
                class="marketingCalendar"
                v-model="focus"
                color="primary"
                locale="fr"
                style="width:100%;"
                :events="events"
                :type="type"
                :event-ripple="false"
                event-more-text="+ {0}"
                :weekdays="[1,2,3,4,5,6,0]"
                @click:event="eventHover"
                @click:more="eventMore"
                ></v-calendar>

                <v-dialog hide-overlay scrollable :attach="'.marketingCalendar'" v-model="moreDialog">
                    <v-card>
                        <v-toolbar tile flat dark  class="bg-gradient" height="45" >
                            <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                                <div class="d-flex align-center">
                                    <v-toolbar-title class="d-flex align-center">{{$t('operationList')}}</v-toolbar-title>
                                </div>
                                <div>
                                    <v-btn text dark depressed @click.native="moreDialog = false">
                                        <v-icon small>$close</v-icon>
                                    </v-btn>
                                </div>
                            </div> 
                        </v-toolbar>
                    
                        <v-card-text>
                            <v-list dense>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in eventDetails"
                                        :key="i"
                                        @click="clickEventMore(item.id)"
                                        >
                                        <div class="d-flex justify-space-between" style="width:100%;">
                                            <span>
                                                {{item.name}}
                                            </span>
                                            <span>
                                                {{ $t('entity') }} : {{ item.social_raison }}
                                            </span>
                                        </div>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-toolbar>
            
        </v-card-text>
    </v-card>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'

export default {
    name:"marketingCalendar",
    props:['operations'],
    mixins: [dateManipulation],
    data() {
        return {
            type: 'month',
            focus: new Date(),
            events: [],
            moreDialog: false,
            eventDetails: []
        }
    },
    watch: {
        operations(){
            this.initEvents();
        }
    },
    created() {
        if(this.operations && this.operations.length > 0)
            this.initEvents();
    },
    computed: {
        currentMonth(){
            if(this.type == 'month')
                return this.capitalizeFirstLetter(this.timestampToDate((this.focus.getTime()/1000), 'monthYear'));
            else 
                return ''
        }
    },
    methods: {
        next(){
            this.$refs.calendar.next()
        },
        prev(){
            this.$refs.calendar.prev()
        },
        setToday () {
            this.focus = new Date();
        },
        initEvents(){
            this.events = []
            this.operations.forEach(element => {
                if(element.attr_date_start && element.attr_date_end)
                    this.events.push({
                        name: element.name,
                        id: element.id,
                        start:new Date(element.attr_date_start.split(" ").join("T")),
                        end: new Date(element.attr_date_end.split(" ").join("T")),
                        color: element.status == 'DRAFT' ? "warning" : (element.status == 'CLOSED' ? "primary" : "info")
                    })
            });
        },
        eventMore($evt){
            let date = $evt.date
            this.eventDetails = this.operations.filter((e) => e.attr_date_start.includes(date))
            this.moreDialog = true
        },
        clickEventMore(id){
            this.$router.push({name: 'OperationDetail', params:{operation_id: id}})
        },
        eventHover($evt){
            this.$router.push({name: 'OperationDetail', params:{operation_id: $evt.event.id}})
        },
        capitalizeFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1);
        }
    },
}
</script>
<style lang="scss">
    .theme--light.v-calendar-events .v-calendar-weekly__day .v-event-more {
        margin-left:1px;
        background-color: rgba(255,255,255,0) !important;
    }
    .marketingCalendar {
        
        .v-calendar-weekly__day-label {
            .v-btn--fab.v-size--small {
                width:25px;
                height:25px;
                .v-btn__content {
                    font-size:12px;
                }
            }
        }
    }
</style>