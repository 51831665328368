<template>
    <v-row class="operationStat">
        <v-col :cols="mini ? '6' : '3'" v-for="(stat, indexName) in stats" @click="goTo(indexName)" :key="indexName">
            <v-menu
                v-if="Object.keys(stat).length > 1"
                offset-y
                center
                open-on-hover
                :close-on-content-click="false"
                content-class="no-shadow testMenu"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-card rounded="lg" link v-bind="attrs" v-on="on" style="height:100%;">
                        <v-card-text style="min-height:200px; width:100%;" class="d-flex flex-column justify-center align-center">
                            <v-btn  :color="stat.global.color" fab depressed>
                                <v-icon color="white">${{ stat.global.icon }}</v-icon>
                            </v-btn>
                            <div class="value"><b>{{ stat.global.value }}</b></div>
                            <p class="textValue">{{$t(indexName)}}</p>
                        </v-card-text>
                    </v-card>
                </template>
                <v-card rounded="lg" class="no-shadow">
                    <v-card-text>
                        <template v-for="(substat, index) in stat">
                            <div v-if="index != 'global'" class="mb-2">
                                <div class="d-flex justify-space-between">
                                    <span class="substat">{{ (indexName == 'opportunities' || indexName == 'leads') ? $t(index) : (indexName == 'fail' ? index : $t(index)) }} ({{ substat }})</span>
                                    <div class="substatPercent">
                                        {{ getPercentage(substat, stat.global.value) }}%
                                    </div>
                                </div>
                                <v-progress-linear
                                color="info"
                                rounded
                                :value="getPercentage(substat, stat.global.value)"
                                ></v-progress-linear>
                            </div>
                        </template>
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-card v-else rounded="lg" link style="height:100%;">
                <v-card-text style="min-height:200px; width:100%;" class="d-flex flex-column justify-center align-center">
                    <v-btn :color="stat.global.color" fab depressed>
                        <v-icon color="white">${{ stat.global.icon }}</v-icon>
                    </v-btn>
                    <div class="value"><b>{{ stat.global.value }}</b></div>
                    <p class="textValue">{{$t(indexName)}}</p>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import GenericDataService from '@/services/GenericDataService';


export default {
    name: 'HomeNumberStatComponent',
    components: {  },
    props: ['mini', 'data', 'switchLinkToUser'],
    data() {
        return{
            operation: {},
            tabModel: 0,
            stats: {},
        }
    },
    mounted() {
       //this.customRefresh() 
       this.stats = this.data
    },
    methods: {
        goTo(indexName){
            if(indexName == 'mission_to_confirm'){
                if(this.switchLinkToUser)
                    this.$router.push({name: 'TodosList',  query: {datatableFilters: '{\"type\":\"RESPOND_MISSION\", \"displayPiscine\":0, \"user\":\"'+this.$store.state.auth.user.id+'\"}'}})
                else
                    this.$router.push({name: 'TodosList',  query: {datatableFilters: '{\"type\":\"RESPOND_MISSION\", \"displayPiscine\":1}'}})
            }else if(indexName == 'opportunities_to_call'){
                if(this.switchLinkToUser)
                    this.$router.push({name: 'TodosList',  query: {datatableFilters: '{\"type\":\"APPEAL_CAMPAIGN\", \"displayPiscine\":0, \"user\":\"'+this.$store.state.auth.user.id+'\"}'}})
                else
                    this.$router.push({name: 'TodosList',  query: {datatableFilters: '{\"type\":\"APPEAL_CAMPAIGN\", \"displayPiscine\":1}'}})
            }else if(indexName == 'leads_to_deal'){
                if(this.switchLinkToUser)
                    this.$router.push({name: 'TodosList',  query: {datatableFilters: '{\"type\":\"LEAD_TREATMENT\", \"displayPiscine\":0, \"user\":\"'+this.$store.state.auth.user.id+'\"}'}})
                else
                    this.$router.push({name: 'TodosList',  query: {datatableFilters: '{\"type\":\"LEAD_TREATMENT\", \"displayPiscine\":1}'}})
            }else if(indexName =='mission_to_staff'){
                if(this.switchLinkToUser)
                    this.$router.push({name: 'TodosList',  query: {datatableFilters: '{\"type\":\"STAFF_MISSION\", \"displayPiscine\":0, \"user\":\"'+this.$store.state.auth.user.id+'\"}'}})
                else
                    this.$router.push({name: 'TodosList',  query: {datatableFilters: '{\"type\":\"STAFF_MISSION\", \"displayPiscine\":1}'}})
            }else if(indexName == 'leads_waiting'){
                if(this.$func.hasRight('lead/leadMenu'))
                    this.$router.push({name: 'LeadsList',  query: {datatableFilters: '{\"realtime_status\":\"WAITING\"}'}})
            }
            
        },
        getPercentage(substat, global){
            if(global == 0)
                return 0;

            let percent = ((substat * 100)/global).toFixed(2);
            if(percent == 0)
                return 0;
            else return percent

        },
        customRefresh(){
            let url = "/home/getStatsV3"
            
            GenericDataService.getData(url).then((response) => {
                this.stats = response.data.data.numbers
            })
           
        }
    },
}
</script>
<style lang="scss">
.operationStat {
    .value {
        margin-top:20px;
        margin-bottom:5px;
        font-size:32px;
        font-weight:800;
    }
    
    .textValue {
        margin-bottom:0px;
    }
    .textStatValue {
        width:80%;
        text-align: center;
        line-height: 18px;
        font-weight:bold;
        font-size:18px;
        margin-top:20px;
        margin-bottom:0px;
    }
}
.testMenu {
    min-width:180px !important;
}
.substat {
    font-size:11px;
    font-weight:bold;
}
.substatPercent {
    font-size:11px;
    font-weight:bold;
}
.testIcon {
    font-size: 56px !important;
    height: 56px !important;
    width: 56px !important;
    svg {
        font-size: 56px !important;
        height: 56px !important;
        width: 56px !important;
    }
}
</style>