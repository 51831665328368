<template>
    <v-row v-if="packId == 1" class="pa-6" :key="statsMarketing+'2'">
        <v-col cols="6">
            <marketingMsgSent v-if="stats && stats.data" :msgSent="stats.data.message_sent" />
        </v-col>
        <v-col cols="6">
            <marketingStats v-if="stats && stats.data" :stats="stats.data.stats" />
        </v-col>
        <v-col cols="12">
            <marketingRetroplanning v-if="stats && stats.data" :retroplannings="stats.data.retroplanning" />
            <alertHomeComponent v-if="stats && stats.data && alertAvailable" :alerts="stats.data.alerts" />
        </v-col>
    </v-row>
    <v-row dense justify="center" class="pa-6 " v-else-if="packId == 2" :key="statsMarketing+'1'">
        <v-col cols="12">
            <homeFiltersComponent v-if="stats && stats.other && stats.other.filters" @removeFilter="removeFilter" :filters="stats.other.filters"/>
        </v-col>
        <v-col cols="12" lg="5" xl="4" class="px-2">
            <marketingStats class="mt-1 mb-2" v-if="stats && stats.data" :stats="stats.data.stats" />
            <todosComponent class="mx-2" v-if="stats && stats.data && stats.data.todos" :todos="stats.data.todos.slice(0, 4)" />
        </v-col>
        <v-col cols="12" lg="7" xl="8" class="px-2">
            <v-row dense>
                <v-col cols="12">
                    
                    <marketingMsgSent v-if="stats && stats.data" :msgSent="stats.data.message_sent" />
                    <marketingRetroplanning v-if="stats && stats.data" :retroplannings="stats.data.retroplanning" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row dense justify="center" class="pa-6 " v-else-if="$func.hasRight('profile/is_marketing') " :key="statsMarketing">
        <v-col cols="12">
            <homeFiltersComponent v-if="stats && stats.other && stats.other.filters" @removeFilter="removeFilter" :filters="stats.other.filters"/>
        </v-col>
        <v-col cols="12" lg="5" xl="4" class="px-2">
            <todosComponent v-if="stats && stats.data && stats.data.todos" :todos="stats.data.todos.slice(0, 4)" />
            <marketingCalendar v-if="stats && stats.data " class="mt-4" :operations="stats.data.operations"/>
        </v-col>
        <v-col cols="12" lg="7" xl="8" class="px-2">
            <v-row dense>
                <v-col cols="12">
                    <marketingStats v-if="stats && stats.data" :stats="stats.data.stats" />
                    <marketingMsgSent v-if="stats && stats.data" :msgSent="stats.data.message_sent" />
                    <marketingRetroplanning v-if="stats && stats.data" :retroplannings="stats.data.retroplanning" />
                    <alertHomeComponent v-if="stats && stats.data && alertAvailable" :alerts="stats.data.alerts" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row dense justify="center" class="pa-6 " v-else-if="apiUrl">
        <v-col cols="12" class="py-0" v-if="$func.hasRight('profile/is_manager') && !$store.state.auth.user.is_presta">
            <v-switch class="switchMe" @change="changeFor" v-model="justMe" inset hide-details :label="$t('onlyMyNumbers')"></v-switch>
        </v-col>
        <v-col cols="4" class="pr-4">
            <v-card v-if="stats && stats.ranking" color="primary" class="mb-4">
                <v-card-text>
                    <div style="position :absolute; top:10px; right:10px; text-align:center;" v-if=" user.coach_detail &&  user.coach_detail.level">
                        <b style="color:white; margin-bottom:5px;">Niveau {{ user.coach_detail.level }}</b>
                        <div  style="display:flex;">
                            <div v-for="index in 5" :class="index <= user.coach_detail.level ? 'dotLevel active' : 'dotLevel'"></div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start align-center">
                        <div>
                            <v-img contain width="140" :src="`${publicPath}`+'img/trophee.png'"></v-img>
                        </div>
                        <div>
                            <p class="mb-0" v-if="generalRanking && stats.ranking && stats.ranking.general">
                                <span class="rankingNumber">{{ generalRanking }}<sup v-if="generalRanking == 1">er</sup><sup v-else>e</sup></span><span class="onRanking">/{{ stats.ranking.general.length }}</span>
                            </p>
                            <p class="mb-0" style="color:white; font-size:16px; margin-top:5px; margin-left:10px;">{{ $t('generalRankingThisYear') }}</p>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            <HomeNumberStatComponent v-if="stats && stats.mininumbers" class="mb-1" :mini="true" :switchLinkToUser="switchLinkToUser" :data="stats.mininumbers"  :key="'mini_'+statsKey"/>
            <missionCalendar v-if="stats && stats.missions" class="" :missions="stats.missions" :indisponibilities="stats.indisponibilities" @initData="initData" />
            <marketingCalendar v-if="stats && stats.ope" class="" :operations="stats.ope"/>
            <homeCalendarComponent  class="mt-0" v-if="stats && stats.rdvs " :data="stats.rdvs" :key="'rdv_'+statsKey"/>
        </v-col>
        <v-col cols="8">
            <v-row  v-if="stats && stats.ranking && ranks" class="mb-1">
                <v-col cols="3" v-for="(rank, name) in ranks" :key="name" style="height:195px;">
                    <v-card class="h-100" @click="openDetailledStats(name)">
                        <v-card-text class=" h-100 d-flex flex-column justify-space-around align-center">
                            <div :class="getMedalClass(rank.rank)">
                                {{rank.rank }}<sup v-if="rank.rank == 1">er</sup><sup v-else>e</sup>
                            </div>
                            <div class="rankingCount primary--text">
                                <b>{{ rank.count }}<span v-if="name == 'satisfaction'">/10</span><span v-if="name == 'ca_influence'">€</span></b>
                            </div>
                           <div class="black--text">{{  $t(name+"_coach") }}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <HomeNumberStatComponent v-if="stats && stats.numbers" class="mb-2" :data="stats.numbers"/>
            <v-card class="pa-2 px-4">
                <DatatableV2 :displayTitle="true" :dense="true" :flat="true" :url="apiUrl"/>
            </v-card>
        </v-col>
        <v-dialog width="700" scrollable v-model="yearlyRankingPopup">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                    <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:start;">
                        <div>
                            <v-btn text dark depressed @click.native="yearlyRankingPopup = false">
                                <v-icon small color="white" class="mr-4" >$prev</v-icon>
                                {{  $t('back') }}
                            </v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text>
                    <div class="my-3"><b style="font-size:16px; color :#000;">{{  $t(yearlyRankingType) }}</b></div>
                    <BarComponent :dataSet="graphData" style="height:400px; width:100%;" :key="graphDetailKey"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import marketingCalendar from '@/components/marketingCalendar'
import homeCalendarComponent from '@/components/homeCalendarComponent'
import DatatableV2 from '@/components/DatatableV2'
import HomeNumberStatComponent from '../../../components/dashboardComponent/HomeNumberStatComponent.vue'
import missionCalendar from '@/components/missionCalendar.vue'
import BarComponent from '@/components/graphs/BarComponent'

import alertHomeComponent from '@/components/dashboardComponent/alertHomeComponent.vue'
import homeFiltersComponent from '@/components/homeFiltersComponent'
import todosComponent from '@/components/dashboardComponent/todosComponent'
import marketingStats from '@/components/marketingStats'
import marketingMsgSent from '@/components/marketingMsgSent'
import marketingRetroplanning from '@/components/marketingRetroplanning'

export default {
    name: "HomeDashboard",
    components: {
        homeCalendarComponent, BarComponent,
        marketingCalendar, DatatableV2, HomeNumberStatComponent, missionCalendar,alertHomeComponent,homeFiltersComponent,todosComponent,marketingStats,marketingMsgSent,marketingRetroplanning},
    data() {
        return {
            justMe: true,
            apiUrl: "/home/getCurrentOperations",
            isEditable: false,
            statsData: [],
            statsKey:0,
            switchLinkToUser: false,
            statsMarketing:0,
            publicPath: process.env.BASE_URL,
            ranks: {},
            yearlyRanking: {},
            yearlyRankingType: "",
            yearlyRankingPopup: false,
            graphData:{ 
                labels: [],
                datasets: [{
                    label: 'Moyenne personnelle',
            
                    data: [10, 20, 30, 40, 50, 60, 70],
                    backgroundColor: ['#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459', '#161459']
                },{
                    label: 'Moyenne de l\'équipe',
                   
                    data: [10, 20, 30, 40, 50, 60, 70],
                    backgroundColor: ['#666', '#666', '#666', '#666', '#666', '#666', '#666', '#666', '#666', '#666', '#666', '#666']
                }],
                options: {
                    responsive:true,
                    maintainAspectRatio: false, 
                    onClick:this.handle,
                    layout: {
                        padding: {
                            left: 0,
                            right: 0, 
                            top: 0,
                            bottom: 0
                        }
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                    },
                    tooltip: {
                        enabled: false
                    },
                    animation: {
                        duration:0,
                    },
                    title:{
                        display: false,
                        text: "test"
                    },
                    scales:{
                        yAxes: [{
                            beginAtZero: true,
                            stacked: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                        xAxes: [{
                            beginAtZero: true,
                            stacked: false
                        }],
                    },
                }
            },
            graphDetailKey:0
        }
    },
    destroyed() {
        this.$store.dispatch('SET_LOADER_DASHBOARD', false)
    },
    watch: {
        user(val){
            setTimeout(() => {
                this.filterDashboard()
            }, 200);
            
        },
        stats() {
            this.statsKey++;
            return this.statsData
        }
    },
    methods: {
        openDetailledStats(type) {
            this.yearlyRanking = {}
            this.graphData.datasets[0].data  = []
            this.graphData.datasets[1].data  = []
            GenericDataService.getData('/user/getYearlyRanking?type='+type).then((response) => {
                let rankingYearly = response.data.data
                for (const month in rankingYearly) {
                    const element = rankingYearly[month]['stats'];
                    this.yearlyRanking[month] = element[type]
                }
                for (const month in this.yearlyRanking) {
                    const element = this.yearlyRanking[month];
                    const coach_number = rankingYearly[month]['nb_coachs']
                    this.graphData.datasets[0].data.push(element.find((e) => e.id == this.user.id).count)
                    this.graphData.datasets[1].data.push((element.reduce((a, b) => a + parseFloat(b.count), 0) / parseInt(coach_number)).toFixed(2))
                }
                
                this.graphData.labels = Object.keys(this.yearlyRanking)
                this.yearlyRankingType = type
                this.yearlyRankingPopup = true
                this.graphDetailKey++   
            })
        },
        getMedalClass(rank){
            let addClass = ''
            switch (rank) {
                case 1:
                    addClass = 'gold'
                    break;
                case 2:
                    addClass = 'silver'
                    break;
                case 3:
                    addClass = 'bronze'
                    break;
            }
            return 'medal '+addClass
        },
        removeFilter(filter, val){
             if(Array.isArray(filter.value)){
                 let index = filter.value.findIndex((e) => e.value == val.value)
                 if(index >=0) 
                     filter.value.splice(index, 1)
             } else {
                 filter.value = ""
            }
            

             let filters = {}
             this.statsData.other.filters.forEach(element => {
                     filters[element.name] = element.value
            });
             this.filterDashboard({filters : filters});
         },
         filterHome(filterSelected){
             for (const filterName in filterSelected) {
                 let filter = this.statsData.other.filters.find((e) => e.name == filterName)
                 filter.value = filterSelected[filterName]
             }

             let payload = {filters : filterSelected}
             this.filterDashboard(payload);
        },
        filterDashboard(payload) {

             GenericDataService.postData('/home/getStats', payload).then((response)=>{
                 this.statsData = response.data.data
                this.statsMarketing++;
                this.$store.dispatch('SET_LOADER_DASHBOARD', false)
             })
         },
        initData(){
            if(this.$func.hasRight('profile/is_marketing')){
                GenericDataService.postData('/home/getStats', []).then((response)=>{
                    this.statsData = response.data.data
                    this.statsMarketing++;
                    this.$store.dispatch('SET_LOADER_DASHBOARD', false)
                })
            } else {
                let filterstest = {}
                if(this.$func.hasRight('profile/is_manager')){
                    filterstest = {
                        filters: {
                            justMe: this.justMe
                        }
                    }
                } else {
                    filterstest = {
                        filters: {
                            justMe: true
                        }
                    }
                }
                GenericDataService.postData('/home/getStatsV3', filterstest).then((response)=>{
                    this.statsData = response.data.data
                    if(this.statsData.ranking){
                        this.ranks = {}
                        for (const key in this.statsData.ranking) {
                            if (Object.hasOwnProperty.call(this.statsData.ranking, key)) {
                                const element = this.statsData.ranking[key];
                                if(key != 'general')
                                    this.ranks[key] = element.find((e) => e.id == this.user.id)
                            }
                        }
                    }
                    this.statsKey++;
                    this.$store.dispatch('SET_LOADER_DASHBOARD', false)
                }).catch((e) => {
                    this.$store.dispatch('SET_LOADER_DASHBOARD', false)
                });
            }
        },
        changeFor($evt){
            this.switchLinkToUser = this.justMe
            this.initData()
        }
    },
    computed: {
        packId(){
            if(this.$store.state.auth.currentUser.accountParameters.packId)
                return this.$store.state.auth.currentUser.accountParameters.packId
            else 
                return false
        },
        generalRanking() {
            return this.stats.ranking.general.find((e) => e.id == this.user.id).position
        },
        alertAvailable(){
            for (const key in this.$store.state.auth.currentUser.accountParameters.alert) {
                if (Object.hasOwnProperty.call(this.$store.state.auth.currentUser.accountParameters.alert, key)) {
                    const element = this.$store.state.auth.currentUser.accountParameters.alert[key];
                    if(element)
                        return true
                }
            }
            return false;
        },
        user(){
            return this.$store.state.auth.user
        },
        stats() {
            return this.statsData
        }
    },
    created() {
        if((this.$func.hasRight('profile/is_manager') && !this.$store.state.auth.user.is_presta) || this.$func.hasRight('profile/is_vendor')){
            this.switchLinkToUser = true
        }
        this.$store.dispatch('SET_LOADER_DASHBOARD', true).then((e) => {
            this.initData()
        })
    },
    // mounted(){
    //     this.$store.dispatch('SET_LOADER_DASHBOARD', true).then((e) => {
    //         this.initData()
    //     })
    // }
}
</script>
<style lang="scss">
.medal {
        background-color: #000;
        color:white;
        border-radius: 50%;
        padding:5px;
        width:50px;
        height:50px;
        font-size:22px;
        font-weight: bold;
        flex-shrink: 0;
        display:flex;
        align-items: center;
        justify-content: center;
        sup {
            font-size:14px;
        }
        &.gold {
            background-color: #e3c034;
        }
        &.silver {
            background-color: #b7b7b7;
        }
        &.bronze {
            background-color: #cca76e;
        }
    }
.rankingCount {
    font-size: 28px; 
    b {
        font-weight:900;
    }  
}
.dotLevel {
    width:10px;
    height:10px; 
    background-color: #FFF;
    border-radius: 5px;
    margin-right:2px;
    margin-left:2px;
    &.active {
      background-color: #ff2987;
    }
  }
    .switchMe {
         .v-input--switch__track {
           height:20px !important;
           width:40px !important;
        }
        .v-input--switch__thumb {
            width: 12px !important;
            height: 12px !important;
        }
        .v-input--selection-controls__ripple {
            width: 26px !important;
            height: 26px !important;
        }
        .v-label {
            margin-bottom:8px !important;
        }
    }
    
    .rankingNumber {
        color:white;
        font-size: 48px;
      font-weight: bold;
      margin-left:10px;

    }
    .onRanking {
        color:white;
        font-size: 32px;
      font-weight: bold;
      margin-left:10px;
    }
  
</style>